// components
import Pass from '@/components/shared/Pass.vue';
// vuex
import { mapMutations } from 'vuex';
// apollo
import { apolloClient } from '@/plugins/apollo';
// api
import { FACLILITY_LIST } from '@/api/graphql/corporateTicket/new-ticket';
import {
  INVENTORY_LIST,
  INVENTORY_TYPE_LIST,
  MW_WEEK_CALENDAR_LIST,
} from '@/api/graphql/inventory/inventory';
// utils
import { formatMonth, formatDate } from '@/constants/functions';
import {
  getDate,
  addMonths,
  addDays,
  getDiff2Days,
  getFirstDateOfMonth,
  getLastDateOfMonth,
  getAllDateInBetween2Dates,
  getDaysArray,
  getISODate,
} from '@/utils/dateUtil';
// lodash
import { sortBy, chain } from 'lodash';
import gql from 'graphql-tag';
import { mwCanExchange } from '../../../../../../utils/my-week';


export const mixin = {
  name: 'ReservationCalendarBlock',
  components: { Pass },
  props: {
    facilityId: Number,
    exchangeReservationType: Number,
    originalMwRank: String,
    allowedInventoryTypes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      facilityListItemSelect: [],
      // date queries
      rangeTimeTemp: '14 days',
      rangeTime: null,
      fromDateTemp: getISODate(new Date()),
      fromDate: null,
      idSelectedFacilityTemp: null,
      menuDate: false,
      // facility
      facilityList: [],
      selectedFacility: {
        id: null,
        name: '全て',
      },
      // room
      roomTypeList: [],
      // inventory
      inventoryList: [],
      inventoryTypeList: [],
      // mwWeek
      mwWeekCalendarDict: {},
      mwWeekNo: {},
      // cells
      selectedReservationRooms: [],
      fetchCount: 0,
    };
  },

  created() {
    this.getFacility();
  },

  async mounted() {
    const facilityId = this.facilityId;

    if (this.productType !== 'MW') {
      await this.fetchFacilityList();
      this.selectedFacility = this.facilityList.find(
        (facility) => facility.id === facilityId,
      );
    } else {
      await this.fetchFacilityListMW();
      this.selectedFacility = this.facilityList.find(
        (facility) => facility.id === facilityId,
      );
    }

    this.getRoomTypeList();
    this.getInventoryTypeList();
  },
  computed: {
    timeRange() {
      return [
        {
          text: '2週間',
          value: '14 days',
        },
        {
          text: '1ヶ月',
          value: 1,
        },
        {
          text: '3ヶ月',
          value: 3,
        },
        {
          text: '6ヶ月',
          value: 6,
        },
      ]
    },
    productType() {
      return this.$route.query.type
    },
    getMWSelection() {
      const type = this.exchangeReservationType;

      if (type === 0) return '全日程';
      else if (type === 1) return '前半';
      return '後半';
    },
    facilityListItem() {
      const copied = this.facilityList.slice();
      // const datas = { // keep this for future use
      //   id: null,
      //   name: '全て',
      // };
      const datas = {
        id: null,
        name: '選択してください',
      };
      copied.unshift(datas);
      return copied;
    },
    toDate() {
      if (!this.fromDate || !this.rangeTime) return null;

      if (this.rangeTime?.endsWith?.('days')) {
        const daysValue = parseInt(this.rangeTime, 10);
        return addDays(this.fromDate, daysValue);
      }

      return addMonths(this.fromDate, this.rangeTime);
    },
    idSelectedFacility: {
      get() {
        return this.selectedFacility ? this.selectedFacility.id : null;
      },
      set(id) {
        const facility = this.facilityList.find((item) => item.id === id);
        this.selectedFacility = facility || {
          id: null,
          name: '全て',
        };
      },
    },
    getSelectedReservationRoomDates() {
      return sortBy(this.selectedReservationRooms.map((ele) => ele.date));
    },
    getSelectedReservationRoomId() {
      return this.selectedReservationRooms.length > 0
        ? this.selectedReservationRooms[0].roomTypeId
        : null;
    },
    getMonthsList() {
      const months = [];
      const type = this.exchangeReservationType;

      months.push({
        value: formatMonth(this.fromDate),
        style: `width: calc(81px * ${this.getVisibleDayInMonth(
          type,
          this.fromDate,
          getLastDateOfMonth(this.fromDate),
        )})`,
      });

      for (let i = 1; i <= this.rangeTime; i++) {
        const date = addMonths(this.fromDate, i);
        const firstDate = getFirstDateOfMonth(date);

        months.push({
          value: formatMonth(date),
          style: `width: calc(81px * ${
            i === this.rangeTime
              ? this.getVisibleDayInMonth(type, firstDate, this.toDate)
              : this.getVisibleDayInMonth(
                  type,
                  firstDate,
                  getLastDateOfMonth(date),
                )
          })`,
        });
      }

      return months;
    },
    mwByFacilityDate () {
      const data = {};
      Object.keys(this.mwWeekNo).forEach(facilityId => {
        const dates = {}
        data[facilityId] = { mwDates: dates };
        Object.keys(this.mwWeekNo[facilityId]).forEach(weekName => {
          const dateArr = this.mwWeekNo[facilityId][weekName];
          dateArr.forEach(date => {
            dates[date] = weekName
          })
        })
      })

      return data;
    },
    dateRange () {
      return getDaysArray(this.fromDate, this.toDate)
    },
    mwDatesWithUndefined () {
      const mwWeekNoResult = {}
      Object.keys(this.mwWeekNo).forEach(facilityId => {
        const result = []
        const appearedDates = new Set()
        const mwWeekByDate = {}
        
        Object.entries(this.mwWeekNo[facilityId]).forEach(([weekName, dates]) => {
          return dates.forEach(date => {
            mwWeekByDate[date] = { weekName, dates, key: date }
          })
        })

        for (const date of this.dateRange) {
          if (appearedDates.has(date)) continue
          const found = mwWeekByDate[date]
          if (found) {
            result.push(found)
            for (const foundDate of found.dates) appearedDates.add(foundDate)
          } else {
            result.push({
              weekName: '-',
              dates: [date],
              key: date
            })
          }
        }
        mwWeekNoResult[facilityId] = result
      })

      return mwWeekNoResult
    }
  },
  watch: {
    fetchCount() {
      this.fetchData();
    }
  },
  methods: {
    getVisibleDayInMonth(type, fromDate, toDate) {
      const dates = getAllDateInBetween2Dates(fromDate, toDate);
      return dates.length;
    },
    addMonths,
    ...mapMutations([
      'setLoadingOverlayHide',
      'setLoadingOverlayShow',
      'setSelectedReservationRooms',
    ]),
    // ================= HANDLE DATE ========================
    getDate,
    formatDate,
    formatMonth,
    nextMonth() {
      this.fromDate = addMonths(this.fromDate, this.rangeTime);
    },
    prevMonth() {
      this.fromDate = addMonths(this.fromDate, -this.rangeTime);
    },
    chooseToday() {
      this.fromDate = new Date().toISOString().substr(0, 10);
    },
    // ================= HANDLE DATA ========================
    getRoomTypeList() {
      let array = [];
      this.facilityList.forEach((facility) => {
        array = array.concat(
          facility.roomTypes.map((roomType) => ({
            backgroundColor: roomType.backgroundColor,
            id: roomType.id,
            name: roomType.name,
            facilityId: facility.id,
          })),
        );
      });

      this.roomTypeList = array;
    },
    async fetchData() {
      this.resetReservationRooms();
      await this.getInventoryList();
      this.getWeekCalendarList();
    },
    async fetchFacilityList() {
      await apolloClient
        .query({
          query: gql`
            ${FACLILITY_LIST}
          `,
        })
        .then((res) => {
          this.facilityList = res.data.facilityList;
        });
    },

    async fetchFacilityListMW() {
      await apolloClient
        .query({
          query: gql`
            query {
              facilityList(filter: { mwAvailable: true }) {
                id
                name
                shortName
                type
                roomTypes {
                  id
                  name
                  backgroundColor
                }
              }
            }
          `,
        })
        .then((res) => {
          this.facilityList = res.data.facilityList;
        });
    },

    async getWeekCalendarList() {
      await this.$apollo
        .query({
          query: gql`
            ${MW_WEEK_CALENDAR_LIST}
          `,
          variables: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            facilityId: this.idSelectedFacility,
          },
        })
        .then((res) => {
          const mwWeekCalendarList = res.data.mwWeekCalendarList;
          const mwWeekCalendarDict = {};
          const mwWeekNo = {};
          mwWeekCalendarList.forEach((mwWeekCalendar) => {
            const facilityId = mwWeekCalendar.facilityId
            if (!mwWeekNo[facilityId]) {
              mwWeekNo[facilityId] = {}
              mwWeekCalendarDict[facilityId] = {}
            }
            const key =
              mwWeekCalendar.weekNo +
              (mwWeekCalendar.latterHalf ? '後半' : '前半');
            const mwDate = mwWeekCalendar.mwDate;

            if (!mwWeekNo[facilityId][key]) {
              mwWeekNo[facilityId][key] = [mwDate];
            } else if (!mwWeekNo[facilityId][key].includes(mwDate)) {
              mwWeekNo[facilityId][key].push(mwDate);
            }
            mwWeekCalendarDict[facilityId][mwWeekCalendar.mwDate] = mwWeekCalendar;
          });
          this.mwWeekCalendarDict = mwWeekCalendarDict;
          this.mwWeekNo = mwWeekNo;
        });
    },
    async getInventoryTypeList() {
      await this.$apollo
        .query({
          query: gql`
            ${INVENTORY_TYPE_LIST}
          `,
          fetchPolicy: 'no-cache',
        })
        .then((data) => {
          const list = data.data.inventoryTypeList;
          this.inventoryTypeList = list;
        });
    },

    getFacilityByRoomTypeId(roomTypeId) {
      for (let i = 0; i < this.facilityListItemSelect.length; i++) {
        for (
          let j = 0;
          j < this.facilityListItemSelect[i].roomTypes.length;
          j++
        ) {
          if (
            this.facilityListItemSelect[i].roomTypes[j].id ===
            parseInt(roomTypeId)
          ) {
            return this.facilityListItemSelect[i];
          }
        }
      }
    },

    getNameFacility(roomTypeId) {
      return this.getFacilityByRoomTypeId(roomTypeId)?.name
    },

    getFacilityIdOfRoomType(roomTypeId) {
      return this.getFacilityByRoomTypeId(roomTypeId)?.id
    },

    async getFacility() {
      await this.$apollo
        .query({
          query: gql`
            query {
              facilityList {
                id
                name
                roomTypes {
                  id
                  name
                }
              }
            }
          `,
        })
        .then((data) => {
          this.facilityListItemSelect = data.data.facilityList;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getInventoryList() {
      this.setLoadingOverlayShow();

      const facilityId = this.idSelectedFacility;
      await this.$apollo
        .query({
          query: gql`
            ${INVENTORY_LIST}
          `,
          variables: {
            dateFrom: this.fromDate,
            dateTo: this.toDate,
            facilityId: facilityId,
            roomTypes: this.roomTypeList
              .filter((roomType) =>
                facilityId ? roomType.facilityId === facilityId : true,
              )
              .map((room) => room.id),
            // inventoryTypes: [4]
          },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          const list = res.data.inventoryList;

          // get unique roomTypeId in order in the list
          const uniqueRoomTypeIds = list
            .map((inventory) => inventory.roomTypeId)
            .filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            })
            .sort((a, b) => this.roomTypeList.findIndex(rt => rt.id === a) - this.roomTypeList.findIndex(rt => rt.id === b));

          const result = chain(list)
            .groupBy('roomTypeId')
            .map((value, key) => ({ roomTypeId: key, calendar: value }))
            .value();

          result.forEach((el) => {
            el.calendar = chain(el.calendar)
              .groupBy('stayDate')
              .map((value, key) => ({ date: key, item: value }))
              .value();
          });

          this.inventoryList = uniqueRoomTypeIds.map((roomTypeId) => {
            const item = result.find(
              (ele) => ele.roomTypeId === '' + roomTypeId,
            );

            return {
              calendar: item ? item.calendar : [],
              roomTypeId: '' + roomTypeId,
            };
          });
        })
        .finally(() => this.setLoadingOverlayHide());
    },
    getColorInventory(id) {
      const inventory = this.inventoryTypeList.find(
        (inventory) => inventory.id === id,
      );

      if (inventory) return inventory.color;
      return '';
    },
    getMwRank(facilityId, date) {
      const mwWeek = this.mwWeekCalendarDict[facilityId]?.[date];

      return mwWeek ? mwWeek.mwRank : '';
    },
    // ================= HANDLE BOOKING DATA ========================
    /**
     *
     * @param {*} roomTypeId
     * @param {*} date
     * @param {*} index
     * @param {*} isSelected
     */
    selectReservationRoom(roomTypeId, roomTypeName, date, index, isSelected, newMwRank) {
      const ok = () => {
        if (!isSelected) {
          this.selectedReservationRooms.push({
            roomTypeId: roomTypeId,
            roomTypeName: roomTypeName,
            date: date,
            index: index,
          });
        } else {
          this.selectedReservationRooms = this.selectedReservationRooms.filter(
            (ele) =>
              !(
                ele.roomTypeId === roomTypeId &&
                ele.date === date &&
                ele.index === index
              ),
          );
        }
        this.selectedReservationRooms.sort(
          (a, b) => new Date(a.date) - new Date(b.date),
        );
        this.setSelectedReservationRooms({
          selectedReservationRooms: this.selectedReservationRooms,
        });
      }
      if (mwCanExchange(this.originalMwRank, newMwRank) || this.selectedReservationRooms.length > 0) {
        ok()
      } else {
        this.$confirm({
          title: `MWランク違い`,
          message: `契約MWランク（${this.originalMwRank}）を超えるMWランク（${newMwRank}）が選択されています。続行しますか？`,
          ok
        })
      }
    },
    isSeletedCell(roomTypeId, date, index) {
      return !!this.selectedReservationRooms.find(
        (ele) =>
          ele.roomTypeId === roomTypeId &&
          ele.date === date &&
          ele.index === index,
      );
    },
    isValidCell(roomTypeId, date, index, inventoryTypeId) {
      if (this.allowedInventoryTypes && this.allowedInventoryTypes.length > 0) {
        if (!this.allowedInventoryTypes.includes(inventoryTypeId)) {
          return false;
        }
      }

      // check mw week calendar exists if mw
      const weekName = this.mwByFacilityDate[this.getFacilityIdOfRoomType(roomTypeId)]?.mwDates[date];
      if (this.productType?.toUpperCase() === 'MW') {
        if (!weekName) {
          // the date doesn't have week number
          return false;
        }

        const type = this.exchangeReservationType
        if (type === 1 && !weekName.includes('前半')) {
          // is not first half date.
          return false
        } else if (type === 2 && !weekName.includes('後半')) {
          // is not latter half date.
          return false
        }
      }

      const selectedRoomTypeId = this.getSelectedReservationRoomId;
      if (!selectedRoomTypeId) return true;
      else if (roomTypeId !== selectedRoomTypeId) return false;

      const dates = this.getSelectedReservationRoomDates;
      const minDate = dates[0];
      const maxDate = dates[dates.length - 1];
      const existDate = this.selectedReservationRooms.find(
        (ele) => ele.date === date,
      );

      if (existDate) {
        if (existDate.index !== index) return false;
        else if (date === minDate || date === maxDate) return true;
        return false;
      }

      if (this.productType?.toUpperCase() === 'MW' && weekName) {
        // make sure the dates are in the same week
        const weekNo = Number(weekName.replace(/[^0-9]/g, ''));
        const selectedWeekNo = Number((
          this.mwByFacilityDate[this.getFacilityIdOfRoomType(roomTypeId)]?.mwDates[minDate]
        ).replace(/[^0-9]/g, ''));
        if (weekNo !== selectedWeekNo) {
          return false;
        }
      }

      if (
        Math.abs(getDiff2Days(minDate, date)) === 1 ||
        Math.abs(getDiff2Days(date, maxDate)) === 1
      ) {
        return true;
      }
      return false;
    },
    resetReservationRooms() {
      this.selectedReservationRooms = [];
    },
    /**
     *
     * @param {*} bookings
     * @param {*} roomName
     * @returns
     */
    checkDuplicateBooking(bookings, roomName) {
      let count = 0;
      for (let i = 0; i < bookings.length; i++) {
        if (roomName === bookings[i].room.name) {
          count = count + 1;
          if (count > 1) return true;
        }
      }

      return false;
    },
    /**
     *
     * @param {*} id
     * @returns
     */
    getBgRoom(id) {
      const roomType = this.roomTypeList.find(
        (roomType) => roomType.id === parseInt(id),
      );
      if (roomType) return roomType.backgroundColor;
      return '';
    },
    /**
     *
     * @param {*} id
     * @returns
     */
    getNameRoomType(id) {
      const roomType = this.roomTypeList.find(
        (roomType) => roomType.id === parseInt(id),
      );

      if (roomType) return roomType.name;
      return '';
    },
    hasMwData (facilityId) {
      return !!this.mwWeekCalendarDict[facilityId]
    },
    loadCalendarData () {
      this.fromDate = this.fromDateTemp;
      this.rangeTime = this.rangeTimeTemp;
      this.idSelectedFacility = this.idSelectedFacilityTemp;
      this.fetchCount++;
    }
  },
};
