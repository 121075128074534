<template>
  <v-dialog v-model="isShow" @click:outside="closeDialog" persistent>
    <div class="frame-selection">
      <div class="header-main">
        <h3 class="font-weight-bold py-4 page-title-main">
          {{ $t('reservationCalendar.titleNoGuest') }}
        </h3>
      </div>
      <div class="px-main">
        <!-- header -->
        <div
          class="d-flex align-center flex-wrap justify-space-between pb-1"
          v-if="selectedReservationRooms.length > 0"
        >
          <!-- choosing -->
          <div class="modal-custom_block d-inline-flex align-center mb-4">
            <h3 class="text-red mr-4">
              {{ $t('reservationCalendar.select') }}
            </h3>
            <div class="mr-4">
              <p class="modal-custom_txt mb-2">
                {{ $t('reservationCalendar.checkIn') }}
              </p>
              <p class="mb-0">
                {{
                  `${formatDate(getSelectedReservationRoomDates[0])}から${
                    selectedReservationRooms.length
                  }泊`
                }}
              </p>
            </div>
            <div class="mr-4">
              <p class="modal-custom_txt font-weight-bold mb-2">
                {{ $t('reservationCalendar.facility') }}
              </p>
              <p
                class="mb-0 text-gray"
                v-if="selectedFacility && selectedFacility.name"
              >
                {{ selectedFacility.name }}
              </p>
            </div>
            <div class="mr-4">
              <p class="modal-custom_txt font-weight-bold mb-2">
                {{ $t('reservationCalendar.typeRoom') }}
              </p>
              <p class="mb-0 text-gray">
                {{ selectedReservationRooms[0].roomTypeName }}
              </p>
            </div>
            <div class="mr-4">
              <p class="modal-custom_txt font-weight-bold mb-2">MW選択範囲</p>
              <p class="mb-0 text-gray">{{ getMWSelection || '' }}</p>
            </div>
          </div>
          <!-- choosing -->
          <!-- header-actions -->
          <div class="header-actions">
            <div>
              <p class="modal-custom_txt font-weight-bold">利用契約</p>
              <p class="text-gray">マイウィーク</p>
            </div>
            <v-btn
              class="btn-close mr-2"
              @click="
                resetReservationRooms();
                closeDialog();
              "
              ><v-icon>mdi-close</v-icon>
              {{ $t('buttons.close') }}
            </v-btn>
            <v-btn color="primary" @click.prevent="next">次へ</v-btn>
          </div>
          <!-- header-actions -->
        </div>
        <!-- header -->
        <!-- body -->
        <div class="modal-custom_block modal-custom_inner mx-0">
          <!-- filter -->
          <div class="flex-header mb-3">
            <v-btn @click="prevMonth" icon
              ><v-icon size="35">mdi-chevron-double-left</v-icon></v-btn
            >
            <v-btn icon
              ><v-icon @click="nextMonth" size="35"
                >mdi-chevron-double-right</v-icon
              ></v-btn
            >
            <v-btn
              @click="chooseToday"
              outlined
              small
              class="mr-5 ml-1 btn-custom-gray"
            >
              {{ $t('common.toDay') }}
            </v-btn>
            <!-- date -->
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <div>
                  <v-icon>mdi-calendar-month</v-icon>
                  <v-btn
                    outlined
                    v-on="on"
                    small
                    class="bg--btn-white mr-5 ml-1 btn-custom-gray"
                    >{{ formatDate(fromDateTemp) }}</v-btn
                  >
                </div>
              </template>
              <v-date-picker
                v-model="fromDateTemp"
                :first-day-of-week="0"
                :locale="$i18n.locale"
                scrollable
                @input="menuDate = false"
                class="v-date-picker-custom"
              ></v-date-picker>
            </v-menu>
            <!-- date -->
            <span class="mr-1">期間</span>
            <v-select
              :items="timeRange"
              item-text="text"
              item-value="value"
              v-model="rangeTimeTemp"
              dense
              small
              color="var(--text__gray)"
              outlined
              hide-details
              class="v-select-custom"
            ></v-select>
            <span class="ml-5 mr-1">{{ $t('common.facility') }}</span>
            <v-select
              :items="facilityListItem"
              v-model="idSelectedFacilityTemp"
              item-text="name"
              item-value="id"
              dense
              small
              color="var(--text__gray)"
              outlined
              hide-details
              class="v-select-custom"
            ></v-select>
            <v-btn
              @click="loadCalendarData"
              :disabled="!idSelectedFacilityTemp"
              outlined
              small
              class="ml-2"
              color="secondary"
            >
              開始
            </v-btn>
          </div>
          <!-- filter -->
          <!-- main -->
          <template v-if="toDate">
            <div class="main_calendar white mx-0 my-0">
              <!-- title -->
              <div class="d-flex">
                <div class="main-calendar__name">
                  <div
                    class="main_calendar_title"
                    v-if="selectedFacility && selectedFacility.name"
                  >
                    {{ selectedFacility.name }}
                  </div>
                  <div class="main_calendar_title" v-else>全て</div>
                </div>
                <div class="main_calendar__header-list">
                  <div
                    v-for="(month, index) in getMonthsList"
                    :key="index"
                    :style="month.style"
                  >
                    {{ month.value }}
                  </div>
                </div>
              </div>
              <!-- title -->
              <!-- room-type -->
              <Pass
                v-for="(item, index) in inventoryList"
                :key="item.roomTypeId"
                :metadataInventory="{
                  roomTypeName: getNameRoomType(item.roomTypeId),
                }"
              >
                <div
                  slot-scope="{ metadataInventory }"
                  :class="`calendar-custom ${index >= 1 ? 'mt-5' : ''}`"
                >
                  <div
                    :style="'background-color: #' + getBgRoom(item.roomTypeId)"
                  >
                    <p style="margin-left: 13px"></p>
                  </div>
                  <!-- room-name -->
                  <div class="calendar-custom_block tt">
                    <div
                      class="calendar-custom_item"
                      v-if="idSelectedFacility == null"
                    >
                      <h3>{{ getNameFacility(item.roomTypeId) }}</h3>
                    </div>
                    <div class="calendar-custom_item" v-else></div>
                    <div class="calendar-custom_item">MWPW週番号</div>
                    <!-- mwWeek -->
                    <div class="calendar-custom_item">MW</div>
                    <div class="calendar-custom_item">
                      {{ metadataInventory.roomTypeName }}
                    </div>
                    <!-- mwWeek -->
                    <div class="mw-weeks">
                      <span
                        v-for="week in mwDatesWithUndefined[getFacilityIdOfRoomType(item.roomTypeId)] || []"
                        :key="week.date"
                        :style="'width: calc(81px *' + week.dates.length + ')'"
                        >{{ week.weekName }}</span
                      >
                    </div>
                    <!-- mwWeek -->
                    <!-- mwWeek -->
                  </div>
                  <!-- room-name -->
                  <!-- calendar -->
                  <div
                    class="calendar-custom_block"
                    v-for="(infoCalendar, index) in item.calendar"
                    :key="index"
                  >
                    <div
                      class="calendar-custom_item"
                      v-text="getDate(infoCalendar.date)"
                      :class="{
                        'text-red':
                          getDate(infoCalendar.date).substr(3, 4) == '(日)',
                      }"
                    ></div>
                    <!-- <div class="calendar-custom_item">
                        <span></span>
                      </div> -->
                    <!-- mwWeek -->
                    <div class="calendar-custom_item">
                      <span></span>
                    </div>

                    <div
                      class="calendar-custom_item"
                      :style="'background-color: #FFC3B1'"
                    >
                      <span>{{ getMwRank(getFacilityIdOfRoomType(item.roomTypeId), infoCalendar.date) }}</span>
                    </div>
                    <div>
                      <span></span>
                    </div>
                    <!-- mwWeek -->
                    <!-- reservations -->
                    <div
                      v-for="(reservation, reservationIndex) in sortInventories(infoCalendar.item)"
                      :key="reservationIndex"
                    >
                      <div
                        class="
                          calendar-custom_item
                          h-auto
                          py-0
                          my-0
                          custom-div-farth
                        "
                        :style="`background-color: #${getColorInventory(
                          reservation.inventoryTypeId,
                        )}`"
                      >
                        <template v-if="reservation.bookings.length > 0">
                          <div
                            class="calendar-custom_item"
                            v-for="booking in reservation.bookings"
                            :key="booking.id"
                            :set="
                              (isDuplicate = checkDuplicateBooking(
                                reservation.bookings,
                                booking.room.name,
                              ))
                            "
                          >
                            <div :style="`background-color: #${getColorInventory(
                              reservation.inventoryTypeId
                            )}`">
                              <span class="calendar-custom_item_txt" :style="checkTextColor(reservation.inventoryTypeId)">
                                {{ booking.room.name }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <Pass
                          v-for="roomIndex in Math.max(0, reservation.rooms - reservation.bookings.length)"
                          :key="roomIndex"
                          :metadata="{
                            index: reservationIndex * 100 + roomIndex,
                            isSelected: isSeletedCell(
                              item.roomTypeId,
                              infoCalendar.date,
                              reservationIndex * 100 + roomIndex,
                            ),
                            isValid: isValidCell(
                              item.roomTypeId,
                              infoCalendar.date,
                              reservationIndex * 100 + roomIndex,
                              reservation.inventoryTypeId
                            ),
                          }"
                        >
                          <div
                            slot-scope="{ metadata }"
                            :class="[
                              'calendar-custom_item',
                              {
                                'calendar-custom_selected': metadata.isSelected,
                                'cursor-pointer': metadata.isValid,
                              },
                            ]"
                            @click="
                              metadata.isValid
                                ? selectReservationRoom(
                                    item.roomTypeId,
                                    metadataInventory.roomTypeName,
                                    infoCalendar.date,
                                    metadata.index,
                                    metadata.isSelected,
                                    getMwRank(getFacilityIdOfRoomType(item.roomTypeId), infoCalendar.date)
                                  )
                                : null
                            "
                          >
                            <span class="calendar-custom_item_txt"></span>
                          </div>
                        </Pass>
                      </div>
                    </div>
                    <!-- reservations -->
                  </div>
                  <!-- calendar -->
                </div>
              </Pass>
              <!-- room-type -->
            </div>
          </template>
          <!-- main -->
        </div>
        <!-- body -->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mixin } from './mixin';

export default {
  name: 'FrameSelection',
  mixins: [mixin],
  props: {
    isShowFrameSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    isShowFrameSelection(value) {
      this.isShow = value;
    },
  },
  methods: {
    checkTextColor (id) {
      const idTextBlack = [1, 4, 3, 5, 6]
      if (!idTextBlack.includes(id)) return 'color: #fff'
      else return 'color: #000'
    },
    
    closeDialog() {
      this.$emit('close');
      this.$emit('update:isShowFrameSelection', false);
    },
    next() {
      this.closeDialog();
      //   this.$emit('next', this.selectedFacility, this.selectedReservationRooms);
    },
    sortInventories (arr) {
      return arr.slice().sort((a, b) => a.inventoryTypeId - b.inventoryTypeId)
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .theme--light.v-chip:not(.v-chip--active) {
    background: none !important;
  }

  .v-select__selections {
    height: 50px !important;
    width: 173px !important;
  }

  .v-input__icon--append {
    color: red !important;
  }

  .modal-custom_block {
    .flex-header {
      color: #000;
      .v-btn--icon {
        color: #757577 !important;
      }
      .btn-custom-gray {
        color: #000;
        border: 1px solid #888;
      }
      .mdi-calendar-month {
        color: #000;
      }
    }
  }
}

.v-application p {
  margin-bottom: 0 !important;
}

.v-select-custom {
  width: 150px;
  flex-grow: 0;
}

div.frame-selection {
  background-color: white;
  padding: 8px 0;
}
.calendar-custom_item {
  color: #000;
  height: 22px;
  line-height: 18px;
  font-size: 11px !important;
  min-width: 80px;
}
.calendar-custom_item:last-child {
  border-bottom: 1px solid #dee2e6;
  padding: 0 !important;
}

.calendar-custom_item_txt {
  margin: 0 10px;
  font-weight: bold;
}
.main_calendar_title {
  width: 164px;
}
.main_calendar_title,
.main_calendar__header-list > div {
  height: 22px;
  line-height: 22px;
}
.custom-div-farth {
  border-bottom: none !important;
  .calendar-custom_item {
    padding: 1px 0;
    .calendar-custom_item_txt {
      margin: 0 2px;
      height: 19px;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid #fff;
    }
    &:not(.cursor-pointer) {
      background-color: #eee;
    }
    &.calendar-custom_selected {
      background-color: #fff;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.calendar-custom_selected {
  box-shadow: 0px 1px 2px #00000029;
  border: 2px solid #ff0000 !important;
}

.calendar-custom_block {
  position: relative;
  width: 81px;
  flex: 0;
  &.tt {
    width: 151px;
    .calendar-custom_item {
      font-size: 12px !important;
      text-align: left;
      min-width: 150px;
    }
  }
  .mw-weeks {
    width: fit-content;
    height: 22px;
    display: flex;
    position: absolute;
    left: 151px;
    top: 21px;
    line-height: 22px;
    text-align: center;
    z-index: 1;

    span {
      width: 152px;
      background-color: white;
      border: 1px solid #dee2e6;
      border-left: 0;
      font-size: 11px;
      border-bottom: 0;
    }
  }
}

.header-actions {
  box-shadow: 0px 2px 3px #00000029;
  padding: 10px;
  border-radius: 3px;
  background-color: #fafafa;
  display: flex;
  text-align: center;
  margin-bottom: 16px;
  & > div {
    width: 151px;
    margin-right: 44px;
    text-align: left;
  }
}
.text-red {
  color: #ff0000 !important;
}
.text-gray {
  color: #444444;
}
p.modal-custom_txt {
  color: #000;
  font-weight: 600;
  margin-bottom: 5px !important;
}
.btn-close {
  background-color: #c92d2d !important;
  border-color: #c92d2d !important;
  color: #fff;
}
</style>
