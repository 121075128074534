<template>
  <div class="px-main --dark py-4">
    <v-row>
      <v-col :cols="5">
        <h1 class="page-title-main ml-3 mt-3">
          MW交換予約作成 {{getDataToExchangeReservation.client.name}} 顧客番号{{getDataToExchangeReservation.client.id}}
        </h1>
      </v-col>
      <v-col :cols="6" class="d-flex justify-end">
        <v-btn
          color="#C92D2D"
          class="mr-3 mt-3 d-flex align-center btn_status white--text"
          @click="$windowClose"
        >
          <v-icon class="white--text"> mdi-close </v-icon>
          閉じる
        </v-btn>
        <v-btn
          class="white--text mr-3 mt-3"
          color="#1873D9"
          :disabled="!checkPerUser"
          @click="showDialogAddMoney()"
        >
          MW交換予約作成
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="mt-5 card-cus" width="95%">
      <v-row>
        <v-col :cols="6" class="mt-5">
          <v-card
            class="ml-8 mx-5 px-6 pt-2 pb-5 card-cus_item"
            color="var(--bg__secondary)"
            elevation="5"
          >
            <v-btn
              :disabled="!checkPerUser"
              class="white--text mt-3"
              color="#1873D9"
              @click="isShowFrameSelection = true"
            >
              カレンダーから入力
            </v-btn>
            <v-col :cols="6" class="mt-3 pl-0 pb-0">
              <span class="text-small">利用契約</span>
              <p class="card-cus_txt">マイウィーク</p>
            </v-col>
            <div class="d-flex">
              <v-row>
                <v-col>
                  <div class="mt-10">
                    <span class="text-small">施設を選択</span>
                    <br />
                    <p class="card-cus_txt">
                      {{ facilityName }}
                    </p>
                  </div>
                </v-col>
                <v-col>
                  <div class="mt-10">
                    <span class="text-small">部屋タイプ</span>
                    <br />
                    <p class="card-cus_txt">
                      {{ roomTypeName }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>

            <!--            date time                -->
            <div class="mt-3 d-flex ml-4">
              <v-col :cols="3">
                <div>
                  <span class="text-small">チェックイン</span>
                </div>
                <div>
                  <v-menu
                    v-model="menuDateFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    class="pb-0 mb-0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex align-center">
                        <v-icon class="ml-n8 mr-2">mdi-calendar-month</v-icon>
                        <v-text-field
                          v-if="getDataToExchangeReservation.dataUpdate"
                          :value="formatDate(checkInDate)"
                          readonly
                          dense
                          outlined
                          hide-details
                          v-bind="attrs"
                          v-on="on"
                          class="text-size-normal pt-0"
                        ></v-text-field>
                        <v-text-field
                          v-else
                          :value="formatDate(dateFrom)"
                          readonly
                          dense
                          outlined
                          hide-details
                          v-bind="attrs"
                          v-on="on"
                          class="text-size-normal pt-0"
                        ></v-text-field>
                      </div>
                    </template>
                    <v-date-picker
                      disabled
                      v-if="getDataToExchangeReservation.dataUpdate"
                      v-model="checkInDate"
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      @input="
                        menuDateFrom = false;
                        menuDateTo = true;
                      "
                      class="v-date-picker-custom"
                    ></v-date-picker>
                    <v-date-picker
                      disabled
                      v-else
                      :value="formatDate(dateFrom)"
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      @input="
                        menuDateFrom = false;
                        menuDateTo = true;
                      "
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col :cols="1" class="d-flex align-end mb-2"> ~ </v-col>
              <v-col :cols="3">
                <div>
                  <span class="text-small">チェックアウト</span>
                </div>
                <div>
                  <v-menu
                    v-model="menuDateTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex align-center">
                        <v-icon class="ml-n8 mr-2">mdi-calendar-month</v-icon>
                        <v-text-field
                          v-if="getDataToExchangeReservation.dataUpdate"
                          :value="formatDate(checkOutDate)"
                          readonly
                          dense
                          outlined
                          hide-details
                          v-bind="attrs"
                          v-on="on"
                          class="text-size-normal pt-0"
                        />
                        <v-text-field
                          v-else
                          :value="formatDate(dateTo)"
                          readonly
                          dense
                          outlined
                          hide-details
                          v-bind="attrs"
                          v-on="on"
                          class="text-size-normal pt-0"
                        />
                      </div>
                    </template>
                    <v-date-picker
                      disabled
                      v-if="getDataToExchangeReservation.dataUpdate"
                      v-model="checkOutDate"
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      :min="dateFrom"
                      scrollable
                      @input="menuDateTo = false"
                      class="v-date-picker-custom"
                    ></v-date-picker>
                    <v-date-picker
                      disabled
                      v-else
                      v-model="dateTo"
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      :min="dateFrom"
                      scrollable
                      @input="menuDateTo = false"
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col :cols="3" class="d-flex align-end pt-0">
                <span class="text-size-normal">
                  {{ getStayDate(checkInDate, checkOutDate) }}</span
                >
              </v-col>
            </div>

            <div class="mt-5 d-flex">
              <div>
                <span class="text-small">MW選択範囲</span>
                <br />
                <p class="card-cus_txt">{{ getMWSelection }}</p>
              </div>
            </div>
          </v-card>

          <v-card
            v-if="!getDataToExchangeReservation.deposit"
            class="ml-8 mx-4 px-6 mt-5 card-cus_item"
            color="var(--bg__secondary)"
            elevation="5"
          >
            <v-col :cols="12" class="mt-3 pl-0 pb-3">
              <p class="card-cus_tt">デポジット</p>
              <div class="d-flex">
                <v-row>
                  <v-col :cols="6">
                    <div>
                      <span class="text-small">予約交換するデポジット</span>
                      <br />
                      <p class="card-cus_txt">{{ getMWSelection }}</p>
                    </div>
                  </v-col>
                  <v-col :cols="6">
                    <div>
                      <span class="text-small">デポジット中範囲</span>
                      <br />
                      <p class="card-cus_txt">全日程</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-card>

          <p
            v-if="getDataToExchangeReservation.deposit"
            class="ml-10 mt-10 mb-0"
            style="color: #db3069"
          >
            デポジット中の範囲がない場合
          </p>
          <v-card
            v-if="getDataToExchangeReservation.deposit"
            class="ml-8 mx-4 px-10"
            color="var(--bg__secondary)"
            elevation="5"
          >
            <v-col :cols="12" class="mt-3 pl-0 pb-0">
              <p style="font-size: 18px; color: #004f8b">デポジット</p>
              <div class="d-flex">
                <v-row>
                  <v-col :cols="6">
                    <div class="mt-10">
                      <span style="color: #db3069" class="text-small"
                        >予約交換するデポジット</span
                      >
                      <br />
                      <p style="color: #db3069">未選択</p>
                    </div>
                  </v-col>
                  <v-col :cols="6">
                    <div class="mt-10">
                      <span class="text-small">デポジット中範囲</span>
                      <br />
                      <p>なし</p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <div>
              <v-btn
                class="white--text my-5"
                color="#DB3069"
                @click="handleOpenDepositDialog"
              >
                デポジット選択
              </v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="6" class="px-10 py-7">
          <div class="mx-4 pa-0">
            <div>
              <v-col :cols="5" class="pa-0">
                <v-text-field
                  v-model="bookingBarNote"
                  label="予約バーに表示させる備考内容"
                  class="text-title text-dark"
                >
                </v-text-field>
              </v-col>
            </div>
            <div class="mt-5">
              <v-combobox
                @change="getSelectName($event)"
                :items="listMembers"
                item-text="name"
                item-value="id"
                label="宿泊代表者"
                dense
                class="text-title text-dark"
              ></v-combobox>
            </div>
            <div class="mt-5">
              <v-text-field
                v-model="representativeKana"
                label="宿泊代表者ヨミガナ"
                class="text-title pt-0"
                :rules="[$rules.checkKatakana]"
              >
              </v-text-field>
            </div>
            <div class="mt-5">
              <v-text-field
                v-model="representativeTel"
                label="電話番号"
                class="text-title pt-0"
              >
              </v-text-field>
            </div>
            <div class="mt-5">
              <v-text-field
                label="メールアドレス"
                class="text-title pt-0"
                v-model="representativeEmail"
              >
              </v-text-field>
            </div>
            <div class="mt-5">
              <v-text-field
                v-model="representativePostalCode"
                label="郵便番号"
                :rules="[$rules.postalCodeNoHyphen]"
                class="text-title pt-0"
              >
              </v-text-field>
            </div>
            <div class="mt-5">
              <v-text-field
                v-model="representativeAddress1"
                label="住所"
                class="text-title pt-0">
              </v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialogMoney"
      max-width="380"
      @click:outside="ClickOutSide()"
    >
      <v-card>
        <v-card-title class="text-h5"> 交換手数料請求 </v-card-title>
        <v-row class="mx-3 mt-1 align-center justify-end">
          <v-col
            :cols="3"
            class="price-tt"
          >
            請求金額 (税抜き)
          </v-col>
          <v-col :cols="8">
            <v-form ref="form" class="form-price">
              <span class="form-price_tt">
                交換手数料 (デフォルト値: <tvos-jpy v-if="contract" :value="contract.mwExchangeFee" />)
              </span>
              <CurrencyInputNumber
                prefix="$"
                v-model="exchangeFeeOverride"
                className="w-50"
                :rules="isNumber"
              ></CurrencyInputNumber>
            </v-form>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-3 my-3" color="#1873D9" @click="actionCreate()">
            <span style="color: #ffffff"> 交換手数料請求作成 </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- I don't think this is being used.
    <DepositMwBookingDialog
      :visible="isOpenDepositDialog"
      @close="isOpenDepositDialog = false"
      multi
      :selected="depositSelected"
      :year="year"
      :id="contractId"
      :depositData="depositData"
    /> -->
    <FrameSelection
      @close="closeFrameDialog()"
      :facilityId="facilityId"
      :exchangeReservationType="getDataToExchangeReservation.type"
      :isShowFrameSelection.sync="isShowFrameSelection"
      :originalMwRank="mwRank"
      :allowedInventoryTypes="[4]"
    />
  </div>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions';
import CurrencyInputNumber from '@/components/Input/CurrencyInputNumber';
import FrameSelection from './components/FrameSelection';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { CLIENT_MEMBER_INFO } from '@/api/graphql/contract/contractDetail/sp_ep/sPUsageStatus/spuSageStatus';
import { MW_WEEK_CALENDAR_LIST } from '@/api/graphql/contract/contractDetail/sp_ep/exChangeReservation/exChangeReservation';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { getOneLineAddress } from '@/utils/string'
import gql from 'graphql-tag';

export default {
  name: 'NewBooking',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      status: false,
      representativeCountry: '',
      representativePostalCode: '',
      representativeName: '',
      representativeKana : '',
      representativeTel : '',
      representativeEmail : '',
      representativeAddress1 : '',
      roomTypeName: '',
      facilityName: '',
      roomTypeId: null,
      facilityId: null,
      depositSelected: 1,
      isOpenDepositDialog: false,
      exchangeFeeOverride: 0,
      dialogMoney: false,
      bookingBarNote: '',
      representativeClientId: null,
      representativeSubMemberId: null,
      statusMember: null,
      listMembers: [],
      showDialog: false,
      isID: true,
      roomType: '禁煙',
      usageContract: 'デイプラン',
      selectFacility: '箱根強羅',
      numNight: 0,
      menuDateFrom: false,
      menuDateTo: false,
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      oldPerson: 3,
      sleepChild: 0,
      nonSleepChild: 0,
      lodgingRepresentative: [],
      country: '日本',
      parkingLotList: [],
      selectParkingLotList: ['機械式1', '機械式2', '機械式3'],
      getDataToExchangeReservation: [],
      contractId: null,
      contract: null,
      year: null,
      firstHalf: null,
      latterHalf: null,
      bookYear: null,
      bookWeekNo: null,
      checkInDate: '',
      checkOutDate: '',
      isShowFrameSelection: false,
      isNumber :[
        v => this.checkNumber(v) || '値は整数でなければなりません。'
      ]
    };
  },
  created() {
    this.getData();
  },

  mounted() {
    this.getListName();
  },

  components: {
    CurrencyInputNumber,
    FrameSelection,
  },

  computed: {
    ...mapGetters(['getSelectedReservationRooms']),
    getMWSelection() {
      const type = this.getDataToExchangeReservation.type;

      if (type === 0) return '全日程';
      else if (type === 1) return '前半';
      return '後半';
    },
    mwRank () {
      return this.getDataToExchangeReservation.mwRank
    }
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setExChangeReservation',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
      'setStatusUpdateDepositMwBooking'
    ]),
    ...mapActions(['exchangeDepositedRight']),

    checkNumber(v) {
      return Number.isInteger(parseInt(v));
    },
    checkType() {
      if (this.getDataToExchangeReservation.type === 0) {
        this.depositSelected = 1;
      } else if (this.getDataToExchangeReservation.type === 1) {
        this.depositSelected = 2;
      } else {
        this.depositSelected = 3;
      }
    },
    async handleOpenDepositDialog() {
      await this.checkType();
      this.isOpenDepositDialog = true;
    },
    getData() {
      this.getDataToExchangeReservation = JSON.parse(
        localStorage.getItem('setDataToExchangeReservation'),
      );
      this.contractId = this.getDataToExchangeReservation.contractId;
      this.year = this.getDataToExchangeReservation.year;
      this.firstHalf = this.getDataToExchangeReservation.firstHalf;
      this.latterHalf = this.getDataToExchangeReservation.latterHalf;
      if (this.getDataToExchangeReservation.type === 0) {
        this.getWeekNo();
      }
    },

    getStayDate(checkInDate, checkOutDate) {
      if (checkInDate !== '' && checkOutDate !== '') {
        const date =
        (new Date(checkOutDate) - new Date(checkInDate)) /
        (1000 * 60 * 60 * 24);
      return date + '泊';
      } else return 0 + '泊'
    },

    showDialogAddMoney() {
      if (this.roomTypeName !== null && this.checkOutDate !== '' && this.checkInDate !== '') {
        this.exchangeFeeOverride = this.contract?.mwExchangeFee ?? null;
        this.dialogMoney = true;
      }
    },

    ClickOutSide() {
      this.exchangeFeeOverride = 0;
    },

    async actionCreate() {
      if (this.roomTypeName !== null && this.checkOutDate !== '' && this.checkInDate !== '') {
        if(this.$refs.form.validate()) {
          const exChangeReservation = {
            bookingBarNote: this.bookingBarNote,
            representativeClientId: this.representativeClientId,
            representativeSubMemberId: this.representativeSubMemberId,
            allowUpgrade: true,
            force: false,
            contractId: this.contractId,
            year: this.year,
            firstHalf: this.firstHalf,
            latterHalf: this.latterHalf,
            bookYear: this.bookYear,
            bookWeekNo: this.bookWeekNo,
            roomTypeId: this.roomTypeId,
            exchangeFeeOverride: this.exchangeFeeOverride,
            representativeName: this.representativeName,
            representativeKana: this.representativeKana,
            representativeTel: this.representativeTel,
            representativeEmail: this.representativeEmail,
            representativeCountry: this.representativeCountry,
            representativePostalCode: this.representativePostalCode,
            representativeAddress1: this.representativeAddress1

          };
          this.dialogMoney = false;
          this.setExChangeReservation({
            exChangeReservation: exChangeReservation,
          });
          const {booking} = await this.exchangeDepositedRight();
          this.$openRoute.booking(booking.id)
          this.$windowClose()
        }
      }
    },

    async getListName() {
      this.listMembers = [];
      const variables = {
            id: this.getDataToExchangeReservation.client.id,
          }
      await this.$apollo
        .query({
          query: gql`${CLIENT_MEMBER_INFO}`,
          variables: variables,
        })
        .then((data) => {
          const client = {
            ...data.data.clientMemberInfo.mainContact,
            id: 999999,
            clientId: data.data.clientMemberInfo.id,
            name: data.data.clientMemberInfo.name,
            nameKana: data.data.clientMemberInfo.nameKana,
          };
          this.listMembers = data.data.clientMemberInfo.subMembers;
          this.listMembers.unshift(client);
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(CLIENT_MEMBER_INFO, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async getWeekNo() {
     if (this.checkInDate !== null && this.checkOutDate !== '') {
      const variables = {
        fromDate: this.checkInDate,
        toDate: this.checkInDate,
        roomTypeId: this.roomTypeId,
      }
      await this.$apollo
      .query({
        query: gql`${MW_WEEK_CALENDAR_LIST}`,
        variables: variables,
      })
      .then((data) => {
        this.bookWeekNo = data.data.mwWeekCalendarList[0].weekNo;
      })
      .catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(MW_WEEK_CALENDAR_LIST, variables, error.graphQLErrors))
        const errorTmp = await handlErrorView(
          error.graphQLErrors,
          this.setPermissionUser,
          this.setRoleAdminUser,
        );
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true });
        }
      });
     }
    },

    getSelectName(event) {
      if (event.id) {
        if (event.id === 999999) {
          this.status = true
          this.representativeClientId = this.listMembers[0].clientId
          this.representativeSubMemberId = null
        } else {
          this.status = true
          this.representativeClientId = null
          this.representativeSubMemberId = event.id
        }
        this.representativeName = event.name
        this.representativeKana = event.nameKana
        this.representativeTel = event.tel
        this.representativeEmail = event.email
        this.representativeAddress1 = getOneLineAddress(event)
        this.representativeCountry = event.locality
        this.representativePostalCode = event.postalCode
      } else {
        this.status = false
        this.representativeName = event
        this.representativeClientId = null
        this.representativeSubMemberId = null
        this.representativeKana = ''
        this.representativeTel = ''
        this.representativeEmail = ''
        this.representativeAddress1 = ''
        this.representativePostalCode = ''
        this.representativeCountry = ''
      }
    },

    async getFacilityList() {
      const query = `
            query {
              facilityList {
                id
                name
                roomTypes {
                  facility {
                    id
                    name
                  }
                  id
                  name
                }
              }
            }
          `
      await this.$apollo
        .query({
          query: gql`${query}`,
        })
        .then((data) => {
          for (let i = 0; i < data.data.facilityList.length; i++) {
            var findFacility = data.data.facilityList[i].roomTypes.find(
              (item) => item.id === this.roomTypeId,
            );
            if (typeof findFacility !== 'undefined') {
              this.facilityId = findFacility.facility.id;
              this.facilityName = findFacility.facility.name;
            }
          }
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, null, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    async closeFrameDialog() {
      if (this.getSelectedReservationRooms.length > 0) {
        this.checkInDate = this.getSelectedReservationRooms[0].date;
        const year = new Date(this.checkInDate)
        this.bookYear = year.getFullYear()
        const lastItem = this.getSelectedReservationRooms[
          this.getSelectedReservationRooms.length - 1
        ];
        const lastOfArr = lastItem.date;
        const lastDay = new Date(lastOfArr)
        this.checkOutDate = lastDay.setDate(lastDay.getDate() + 1)
        this.roomTypeId = parseInt(
          this.getSelectedReservationRooms[0].roomTypeId,
        );
        this.roomTypeName = this.getSelectedReservationRooms[0].roomTypeName;
        await this.getWeekNo()
        this.getFacilityList();
      }
    },

    formatDate(value) {
      var d = moment(value).format('dddd');
      switch (d) {
        case 'Sunday':
          d = '日';
          break;
        case 'Monday':
          d = '月';
          break;
        case 'Tuesday':
          d = '火';
          break;
        case 'Wednesday':
          d = '水';
          break;
        case 'Thursday':
          d = '木';
          break;
        case 'Friday':
          d = '金';
          break;
        case 'Saturday':
          d = '土';
          break;
      }
      if (moment(value) > moment(this.dateTo)) {
        this.dateTo = value;
      }
      return moment(value).format('yyyy年MM月DD日 (' + d + ')');
    },
  },
  apollo: {
    contract: {
      query: gql`query ($id: Int!) { contract(id: $id) { mwExchangeFee } }`,
      variables() {
        return { id: this.contractId }
      },
      skip() { return !this.contractId },
      update: data => data.contract
    }
  }
};
</script>

<style scoped lang="scss">
.btn-add-parking {
  border: var(--text__primary) 2px solid;
  background-color: var(--bt__while) !important;
  color: var(--text__primary);
}
.text-title::v-deep label {
  font-size: 12px !important;
  font-weight: bold;
  color: #AAAAAA;
}
.text-h5 {
  font-weight: 600 !important;
  color: #212121;
  letter-spacing: 2px !important;
  font-size: 28px;
  font-family: 'Noto Sans', sans-serif !important;
}
.price-tt {
  padding: 0px !important;
  text-align: center;
  font-size: 16px;
  color: #666;
}
.disable {
  pointer-events: none;
}
::v-deep {
  .form-price {
    &_tt {
      display: inline-block;
      font-size: 10px;
      color: #3083DC;
    }
    .v-text-field {
      margin-top: 0;
      padding-top: 0;
    }
    .v-text-field__slot {
      input {
        color: #666;
        font-size: 16px;
      }
    }
  }
  .text-dark {
    label, input {
      color: #000 !important;
    }
  }
}
.px-main {
  background-color: #f8f8f8;
  height: 100%;
  .card-cus {
    box-shadow: none;
    padding-bottom: 30px;
    border-radius: 0;
    &_item {
      box-shadow: 0px 3px 6px #00000029 !important;
      background-color: #fafafa !important;
      color: #000;
      font-weight: 500;
    }
    &_tt {
      color: #004F8B;
      font-size: 16px;
      font-weight: 600;
    }
    &_txt {
      color: #444444;
    }
  }
}
</style>
